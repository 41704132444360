import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";

export default function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <Link to='/pgp' className='App-link'>PGP Public Key</Link>
      </header>
    </div>
  );
}
